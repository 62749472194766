import { Controller } from "@hotwired/stimulus"
import { ajax } from "@rails/ujs";

export default class extends Controller {
  static targets = ['overlay', 'spinner']

  connect() {
    document
      .addEventListener('promote:import-editor-opened', this._showOverviewOverlay.bind(this));
    document
      .addEventListener('promote:import-editor-closed', this._hideOverviewOverlay.bind(this));
  }

  disconnect() {
    document
      .removeEventListener('promote:import-editor-opened', this._showOverviewOverlay.bind(this));
    document
      .removeEventListener('promote:import-editor-closed', this._hideOverviewOverlay.bind(this));
  }

  onSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.target;
    const formData = new FormData(form);

    const action = form.action;
    const method = form.method;

    ajax({
      url: action,
      type: method,
      data: formData,
      beforeSend: (xhr) => {
        this._showLoadOverlay()

        this.ajaxRequest = xhr;
        return true;
      },
      success: () => {
        this._hideLoadOverlay();
      },
      error: () => {
        this._hideLoadOverlay();
      }
    });
  }

  _showLoadOverlay() {
    this.spinnerTarget.classList.remove('hidden');

    this.overlayTarget.style.zIndex = '90'; // Submenus in overview have z-index 90
    this.overlayTarget.classList.remove('hidden');
  }

  _hideLoadOverlay() {
    this.spinnerTarget.classList.add('hidden');
    this.overlayTarget.classList.add('hidden');
  }

  _showOverviewOverlay() {
    this.spinnerTarget.classList.add('hidden');

    this.overlayTarget.style.zIndex = '40'; // Editor has z-index 50
    this.overlayTarget.classList.remove('hidden');
  }

  _hideOverviewOverlay() {
    this.spinnerTarget.classList.add('hidden');
    this.overlayTarget.classList.add('hidden');
  }
}

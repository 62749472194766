import { ajax } from '@rails/ujs';
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['lockByDateForm', 'startDate', 'form']

  displayForm() {
    this.lockByDateFormTarget.classList.remove('hidden')
  }

  hideForm() {
    this.lockByDateFormTarget.classList.add('hidden')
  }

  preventToggle() {
    event.preventDefault();
  }

  submitForm(event) {
    event.preventDefault();

    const form = this.formTarget;
    const formData = new FormData(form);

    const action = form.action;
    const method = form.method;

    ajax({
      url: action,
      type: method,
      data: formData,
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      }
    });
  }
}


